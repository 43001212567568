import { useFormState } from "react-hook-form";
import React from "react";

type UseErrorResponse = false | string;

const useError: (name: string) => UseErrorResponse = (name) => {
  const formState = useFormState();
  const [error, setError] = React.useState<UseErrorResponse>(false);

  React.useEffect(() => {
    if (formState.errors.hasOwnProperty(name)) {
      setError(formState.errors[name].message ?? "To pole jest obowiązkowe");
    } else {
      setError(false);
    }
  }, [formState, name]);

  return error;
};

export default useError;
