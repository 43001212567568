const ThemeStyle = {
  colors: {
    green: "#305C41",
    greenHover: "#4c8b64",
    yellow: "#FADC0A",
    red: "#FF4141",
    beige: "#F4E9C9",
    beigeDark: '#F4E9C9',
    black: "#494949",
    blackHover: "#000",
    blackDark: "#251D17",
    white: "#fff",
    whiteHover: "#d0d0d0",
    error: "rgba(255, 0, 0, 0.5)",
    placeholder: "rgba(48,92,65,0.45)"
  },
  fonts: {
    main: "trade-gothic-next-compressed, sans-serif",
    second: "",
  },
  fontsWeight: {
    light: 300,
    regular: 400,
    bold: 700,
    heavy: 800,
  },
  transition: "all .3s ease-in-out",
  maxWidth: "1920px",
  padding: {
    sm: "3.5rem",
    md: "4rem",
    lg: "4rem",
    xl: "6rem",
    xxl: "9rem",
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1440px",
    ultra: "1600px",
    nav: "1100px",
  },
};

export default ThemeStyle;
