import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { TextLabel } from "../../../styles/BasicStyles";
import { mediaDown, mediaUp } from "../../../styles/Mixins";

type AgeInputProps = {
  maxlength?: string;
  state: "typing" | "error" | "valid" | "empty";
  filled: boolean;
};

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
`;

export const Input = styled.input<AgeInputProps>`
  width: 100%;
  min-width: 6rem;
  font-weight: 700;
  font-size: 2.8rem;
  text-align: center;
  font-family: ${ThemeStyle.fonts.main};
  color: ${ThemeStyle.colors.green};
  padding: 1.5rem 2rem;
  border: 0.1rem solid ${ThemeStyle.colors.green};
  margin: 0;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 1rem;
      font-size: 2.2rem;
    `
  )};

  &.year {
    width: 20rem;

    ${mediaDown(
      ThemeStyle.breakpoints.sm,
      css`
        width: 15rem;
      `
    )};
  }

  &::placeholder {
    color: ${ThemeStyle.colors.green};
    font-size: 2.8rem;
  }

  ${(props) =>
    props.state === "error" &&
    css`
      border-color: ${ThemeStyle.colors.error};
    `}
`;

export const Label = styled.label<AgeInputProps>`
  ${TextLabel};
  font-size: 4rem !important;
  color: ${ThemeStyle.colors.green};
  ${(props) =>
    props.filled &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;
