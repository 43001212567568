import styled from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";

export const AgeErrorText = styled.p`
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.1;
  color: ${ThemeStyle.colors.error};
  transition: all 0.3s ease-in-out;
  transition-property: opacity, visibility;
`;
