import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { AppContainer } from "../../styles/BasicStyles";
import background from "../../images/Common/bg-age.jpg";
import { TitleLarge, TextLarge } from "../../styles/BasicStyles";
import { mediaDown } from "../../styles/Mixins";
import BeeGif from "../../images/Common/bee-large.gif";
import BeeGifMobile from "../../images/Common/bee-large-mobile.gif";

export const AgeCheckerSection = styled.section`
  width: 100%;
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11000;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      min-height: -webkit-fill-available;
    `
  )}
`;

export const Container = styled.div`
  ${AppContainer()};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
`;

export const Bee = styled.div`
  background: url(${BeeGif}) center/contain no-repeat;
  width: 19rem;
  height: 19rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: url(${BeeGifMobile}) center/contain no-repeat;
      width: 10rem;
      height: 10rem;
    `
  )}
`;

export const Title = styled.h2`
  ${TitleLarge};
  font-size: 6.4rem;
  text-align: center;
  margin-bottom: 3rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 3.5rem;
    `
  )}
`;

export const Subtitle = styled.h3`
  ${TextLarge};
  font-size: 2.9rem;
  line-height: 3.5rem;
  max-width: 45rem;
  text-align: center;
  margin-bottom: 5rem;

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      max-width: 40rem;
      font-size: 2rem;
      line-height: 1.2;
      margin-bottom: 3rem;
    `
  )};
`;

export const FormPlaceholder = styled(Subtitle)``;
