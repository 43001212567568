import React, { FunctionComponent } from "react";
import * as Styled from "./AgeCheckerSection.styled";
import Form from "../../components/AgeForm/Form/Form";

type Props = {
  onChange: (isLegalAge: boolean) => void;
};

const AgeCheckerSection: FunctionComponent<Props> = (props) => {
  const legalAgeChangeHandler = (x: boolean) => {
    if (x) {
      sessionStorage.setItem("isLegalAge", "1");
    }
    props.onChange(x);
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("isLegalAge") === "1") {
      props.onChange(true);
    }
  }, []);

  return (
    <Styled.AgeCheckerSection>
      <Styled.Container>
        <Styled.Wrapper>
          <Styled.Bee />
          <Styled.Title>BEEfor od lat 18!</Styled.Title>
          <Styled.Subtitle>
            MUSISZ MIEĆ 18 LAT, ABY WEJŚĆ NA STRONĘ. WPISZ SWOJĄ DATĘ URODZENIA
          </Styled.Subtitle>
          <Form legalAgeChangeHandler={legalAgeChangeHandler} />
        </Styled.Wrapper>
      </Styled.Container>
    </Styled.AgeCheckerSection>
  );
};

export default AgeCheckerSection;
