import styled, { css } from "styled-components";
import ThemeStyle from "../../../styles/ThemeStyle";
import { TextLabel, TextNormal } from "../../../styles/BasicStyles";
import { mediaDown, mediaUp } from "../../../styles/Mixins";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 1rem;
`;

export const Submit = styled.input`
  ${TextLabel};
  font-family: ${ThemeStyle.fonts.main};
  font-size: 2.4rem;
  color: ${ThemeStyle.colors.white};
  padding: 2rem 10rem;
  background: ${ThemeStyle.colors.green};
  clip-path: polygon(25% 0%, 75% 0%, 83% 50%, 75% 100%, 25% 100%, 17% 50%);
  margin: 3rem 2rem;
  cursor: pointer;
  transition: ${ThemeStyle.transition};

  &:hover {
    background: ${ThemeStyle.colors.yellow};
  }
`;

export const SmallText = styled.h4`
  ${TextNormal};
  color: ${ThemeStyle.colors.error};
  font-size: 2.2rem;
  text-align: center;
`;
