import React, { FunctionComponent } from "react";
import * as Styled from "./AgeErrorText.styled";

type Props = {
  text: string | undefined;
};

const AgeErrorText: FunctionComponent<Props> = (props) => {
  return <Styled.AgeErrorText>{props.text}</Styled.AgeErrorText>;
};

export default AgeErrorText;
