import React, { FunctionComponent } from "react";
import * as Styled from "./AgeInput.styled";
import { useFormContext, useWatch } from "react-hook-form";
import { AgeInputProps } from "../AgeFormProps";
import useError from "../useError";
import AgeErrorText from "../AgeErrorText/AgeErrorText";

type Props = AgeInputProps & {
  className?: string;
  maxlength?: number;
  max?: number;
  id: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: (y: string) => void;
  pattern?: {
    value: RegExp;
    message: string;
  };
};

const AgeInput: FunctionComponent<Props> = ({
  className,
  inputValue,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const value = useWatch({ name: props.name });
  const error = useError(props.name);
  const [state, setState] = React.useState<
    "typing" | "error" | "valid" | "empty"
  >("empty");

  React.useEffect(() => {
    if (error) {
      setState((prev) => (prev === "typing" ? prev : "error"));
    } else {
      setState(value ? "valid" : "empty");
    }
  }, [error]);

  React.useEffect(() => {
    if (value) {
      inputValue(value);
    }
  }, [value]);

  return (
    <Styled.InputWrapper>
      <Styled.Input
        type="number"
        state={state}
        {...register(props.name, {
          required: props.required,
          maxLength: props.maxlength,
          max: props.max,
          pattern: props.pattern,
        })}
        placeholder={props.placeholder ?? props.label}
        onFocus={() => setState("typing")}
        onBlur={() => setState(error ? "error" : value ? "valid" : "empty")}
        filled={state !== "empty"}
        className={className}
        max={props.max}
        min={1}
      />

      {error && <AgeErrorText text={props.pattern?.message} />}
    </Styled.InputWrapper>
  );
};

export default AgeInput;
