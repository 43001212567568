import React, { useEffect, useRef } from "react";
import * as Styled from "./Form.styled";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AgeInput from "../AgeInput/AgeInput";
import scrollToElement from "../../../functions/scrollToElement";

type FormType = {
  year: number;
  month: number;
  day: number;
};

type TestType = {
  legalAgeChangeHandler: (x: boolean) => void;
};

const Form: React.FC<TestType> = ({ legalAgeChangeHandler }) => {
  const methods = useForm<FormType>();
  const [pristine, setPristine] = React.useState(true);

  const onSubmit = (data: FormType) => {
    const date = new Date(data.year, data.month - 1, data.day);
    const now = new Date();
    const age = now.getFullYear() - date.getFullYear();

    legalAgeChangeHandler(age >= 18);
    if (age < 18) {
      setPristine(false);
    } else {
      if (location.hash) {
        const id = location.hash.substring(1);
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView();
        }
      }
    }
  };

  const dayInputValue = (y: string) => {
    if (y.length == 2) {
      methods.setFocus("month");
    }
  };
  const monthInputValue = (y: string) => {
    if (y.length == 2) {
      methods.setFocus("year");
    }
  };
  const yearInputValue = (y: string) => {};

  return (
    <Styled.Wrapper>
      <FormProvider {...methods}>
        <Styled.Form onSubmit={methods.handleSubmit(onSubmit)}>
          {pristine && (
            <Styled.Inputs>
              <AgeInput
                inputValue={dayInputValue}
                id="day"
                name={"day"}
                label={"DD"}
                maxlength={2}
                required={true}
                max={31}
                pattern={{
                  value: /(0?[1-9]|[12][0-9]|3[01])/i,
                  message: "Podaj poprawny dzień",
                }}
              />
              <AgeInput
                inputValue={monthInputValue}
                id="month"
                name={"month"}
                label={"MM"}
                maxlength={2}
                required={true}
                max={12}
                pattern={{
                  value: /(0?[1-9]|1[0-2])/i,
                  message: "Podaj poprawny miesiąc",
                }}
              />
              <AgeInput
                inputValue={yearInputValue}
                id="year"
                name={"year"}
                label={"RRRR"}
                maxlength={4}
                required={true}
                max={new Date().getFullYear()}
                pattern={{
                  value: /(19|20)\d{2}/i,
                  message: "Podaj poprawny rok",
                }}
                className="year"
              />
            </Styled.Inputs>
          )}
          {!pristine && (
            <Styled.SmallText>
              Musisz mieć skończone 18 lat, by wejść na tę stronę.
            </Styled.SmallText>
          )}
          {pristine && <Styled.Submit type="submit" value="Wchodzę" />}
        </Styled.Form>
      </FormProvider>
    </Styled.Wrapper>
  );
};

export default Form;
