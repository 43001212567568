import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from "styled-components";

export const mediaUp = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>
) => css`
  @media only screen and (min-width: ${media}) {
    ${children}
  }
`;
export const mediaDown = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>
) => css`
  @media only screen and (max-width: ${media}) {
    ${children}
  }
`;

export const Transition = (
  properties: string = "all",
  time: number = 0.4
) => css`
  transition: ${properties} ${time}s;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.8, 0.47);
`;
