import styled, { css } from "styled-components";
import ThemeStyle from "./ThemeStyle";
import { mediaDown } from "./Mixins";
import BackgroundDesktop from "../images/Common/bg-end.jpg";
import BackgroundMobile from "../images/Common/bg-end-mobile.jpg";

export const AppContainer = (padding?: string) => css`
  display: flex;
  flex: 0 0 100%;
  margin: 0 auto;
  max-width: ${ThemeStyle.maxWidth};
  padding-left: ${padding || ThemeStyle.padding.xxl};
  padding-right: ${padding || ThemeStyle.padding.xxl};
  ${mediaDown(
    ThemeStyle.breakpoints.xl,
    css`
      padding-left: ${padding || ThemeStyle.padding.xl};
      padding-right: ${padding || ThemeStyle.padding.xl};
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      padding-left: ${padding || ThemeStyle.padding.lg};
      padding-right: ${padding || ThemeStyle.padding.lg};
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      padding-left: ${padding || ThemeStyle.padding.md};
      padding-right: ${padding || ThemeStyle.padding.md};
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-left: ${padding || ThemeStyle.padding.sm};
      padding-right: ${padding || ThemeStyle.padding.sm};
    `
  )}
`;

export const TitleLarge = css`
  color: ${ThemeStyle.colors.green};
  font-size: 17rem;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  span {
    display: block;
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 6rem;
    `
  )}
`;

export const TitleColored = (bgColor?: string) => css`
  color: ${ThemeStyle.colors.green};
  font-size: 5.5rem;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  padding: 1rem 4rem;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: ${bgColor === "white"
      ? ThemeStyle.colors.white
      : ThemeStyle.colors.yellow};
    height: ${bgColor === "white" ? "50%" : "100%"};
    width: 100%;
    z-index: -1;
  }

  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 3.2rem;
    `
  )}
`;

export const TextLarge = css`
  color: ${ThemeStyle.colors.green};
  font-size: 3.2rem;
  text-transform: uppercase;
  font-weight: 800;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.4rem;
    `
  )};
`;

export const TextNormal = css`
  color: ${ThemeStyle.colors.green};
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.3;
`;

export const TextLabel = css`
  color: ${ThemeStyle.colors.green};
  font-size: 2.4rem;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  height: 100%;
`;

export const LinkBase = styled.a`
  display: flex;
  align-items: center;
  margin: 0 4rem;
  font-weight: 700;
  padding: 1.5rem 0;
  font-size: 1.6rem;
  line-height: 1;
  color: ${ThemeStyle.colors.green};
  text-transform: uppercase;
  transition: ${ThemeStyle.transition};
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1rem;
    height: 0.1rem;
    width: 100%;
    opacity: 0;
    background-color: ${ThemeStyle.colors.green};
    transition: ${ThemeStyle.transition};
    transition-property: bottom, opacity;
  }
  &:hover {
    &:before {
      bottom: 1rem;
      opacity: 1;
    }
  }
`;

export const Link = styled(LinkBase)``;

export const Background = styled.div`
  background: url("${BackgroundDesktop}") bottom/cover no-repeat;
  z-index: 2;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      background: url("${BackgroundMobile}") bottom/cover no-repeat;
    `
  )};
`;
